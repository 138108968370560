// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()

require("channels")
require("turbolinks").start()

require("@popperjs/core")
require("datatables")

require("select2")

require("nice-select")
require("mmenu")
require("script")
require("js.cookie.min")
require("js.cookie.custom")

import "./stylesheets/style"
import "bootstrap"
import "./main"

import { Tooltip, Popover } from "bootstrap"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//


const images = require.context('./images', true)
const imagePath = (name) => images(name, true)
var jQuery = require('jquery');

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import $ from 'jquery';
window.jQuery = $;
window.$ = $;


import { DirectUpload } from "@rails/activestorage"

// direct_uploads.js

addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail
    target.insertAdjacentHTML("beforebegin", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename"></span>
      </div>
    `)
    target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
  })
  
  addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
  })
  
  addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  })
  
  addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
  })   

  addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
  })

  

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
//   $('select.niceselect').niceSelect();
//   $('.custom-search-form select').niceSelect();
})


$(document).ready(function() {
    // const input = document.querySelector('input[type=file]')
    const input = document.querySelector('.file_direct_upload')
    
    // Bind to file drop - use the ondrop on a parent element or use a
    //  library like Dropzone
    const onDrop = (event) => {
      event.preventDefault()
      const files = event.dataTransfer.files;
      Array.from(files).forEach(file => uploadFile(file))
    }
    
    // Bind to normal file selection
    input.addEventListener('change', (event) => {
      Array.from(input.files).forEach(file => uploadFile(file))
      // you might clear the selected files from the input
      input.value = null
    })
    
    $( "#upload_attachment_button" ).click(function() {
      Array.from(input.files).forEach(file => uploadFile(file))
      // you might clear the selected files from the input
      input.value = null
    });

    const uploadFile = (file) => {
      // your form needs the file_field direct_upload: true, which
      //  provides data-direct-upload-url
      const url = input.dataset.directUploadUrl
      const upload = new DirectUpload(file, url)
    
      upload.create((error, blob) => {
        if (error) {
          // Handle the error
        } else {
          // Add an appropriately-named hidden input to the form with a
          //  value of blob.signed_id so that the blob ids will be
          //  transmitted in the normal upload flow
          const hiddenField = document.createElement('input')
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.name = input.name
          document.querySelector('form').appendChild(hiddenField)

          const image = '<img src="/file/blobs/' + blob.signed_id + '/' + blob.filename + '" width=30 height=26>'

          $('.post-attachments > tbody:last-child').append('<tr><td><input type="checkbox" class="form-check-input"></td><td valign="middle" align="center">'+image+'</td><td>'+blob.filename+'</td><td><a class="btn btn-primary" data-confirm="Are you sure?" rel="nofollow" data-method="delete" href="/delete_blob/'+blob.key+'">Remove</a></td><td><button onClick="copyURI(\'/file/blobs/'+blob.signed_id+'/'+blob.filename+'\'); return false;" class="btn btn-primary" style="font-size:0.8rem">Copy URL</button></td></tr>')

          console.log(blob)
        }
      })
    }

    

})
    