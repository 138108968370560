window.suggest = function() {
  var val = $('#query').val();
  var type = $("#type").val();
  if(val.length > 1) {
    $.get("/search/search?query=" + val + "&type=" + type, function(data, status){
      $("#autocomplete").html(data);
    });
  }
  else {
    $("#autocomplete").html('');
  }
}

$(function() {
//document.addEventListener('turbolinks:load', () => {
  // Sticky nav
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 1) {
      $('.header').addClass("sticky");
    } else {
      $('.header').removeClass("sticky");
    }
  });

  var $menu = $("nav#menu").mmenu({
    navbar: {
      title: 'opendatalab.mn',
    },
    navbars: []
    },
    {
    clone: true,
    classNames: {
      fixedElements: {
        fixed: "menu_fixed",
        sticky: "sticky"
      }
    }
  });
  var $icon = $("#hamburger");
  var API = $menu.data("mmenu");
  $icon.on("click", function () {
    API.open();
  });
  API.bind("open:finish", function () {
    setTimeout(function () {
      $icon.addClass("is-active");
    }, 100);
  });
  API.bind("close:finish", function () {
    setTimeout(function () {
      $icon.removeClass("is-active");
    }, 100);
  });

  // Rotate icons
  $(".main_categories a").on('mouseenter', function() {
    $(this).find("h4").toggleClass("rotate-x");}
  );

});